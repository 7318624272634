<template lang="">
  <v-row>
    <v-col cols="12" md="6">
      <v-autocomplete
        outlined
        :value="brands.idbrandSelected"
        :items="getBrands"
        label="Marca (*)"
        @change="setSubBrands"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        outlined
        :value="brands.idSubBrandSelected"
        :items="getSubBrands"
        label="Submarca (*)"
        @change="setSubBrandId"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "SelectBrandsSubBrands",
  methods: {
    async setSubBrands(e) {
      await this.$store.dispatch("setIdBrandSelected", e);
      await this.$store.dispatch("setIdSubBrandSelected", null);
      this.$emit("setSubBrandSelected");
    },
    async setSubBrandId(e) {
      await this.$store.dispatch("setIdSubBrandSelected", e);
      this.$emit("setSubBrandSelected");
    },
  },
  computed: {
    ...mapState({
      brands: (state) => state.brands,
    }),
    ...mapGetters(["getBrands", "getSubBrands"]),
  },
};
</script>
<style lang=""></style>
