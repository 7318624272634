<template>
  <v-container id="body-container-card-fvl">
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <LoaderLinear />
    <div id="fvl-container-card-table">
    <v-card elevation="0" class="fvl-card-searcher-table">
      <div class="d-flex justify-center">
        <v-text-field
         v-model="search"
          class="mb-6 searcher-input-fvl elevation-1"
          label="Buscador de marcas"
          append-icon="search"
          dense
          filled
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        :headers="dataTableheaders"
        :items="brands.brands"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="
              user.permissions.indexOf(permissionsAll.brand_update) >= 0 ||
                user.person.types_person.id === 3
            "
          >
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="
              user.permissions.indexOf(permissionsAll.brand_delete) >= 0 ||
                user.person.types_person.id === 3
            "
          >
            delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    </div>
    <SnackBar />
    <ButtonAdd
      :link="linkAdd"
      v-if="
        user.permissions.indexOf(permissionsAll.brand_create) >= 0 ||
          user.person.types_person.id === 3
      "
    />
    <DialogConfirm @action="deleteBrand" />
    <Overlay />
  </v-container>
</template>

<script>
import router from "../../router";
import {
  links,
  itemsBreadCrumbs,
  beginRequest,
  endRequest,
} from "../../helpers";
import {
  LoaderLinear,
  SnackBar,
  Overlay,
  DialogConfirm,
  BreadCrumbs,
  ButtonAdd,
} from "../_commons";
import { mapState } from "vuex";
import permissions from "../../helpers/permissions";
export default {
  name: "CardBrands",
  components: {
    BreadCrumbs,
    ButtonAdd,
    LoaderLinear,
    DialogConfirm,
    SnackBar,
    Overlay,
  },
  data() {
    return {
      linkAdd: links.brand.create,
      itemsBreadCrumbs: itemsBreadCrumbs.brandList,
      search: "",
      dataTableheaders: [
        { text: "id", value: "id", class:"file-name-table" },
        {
          text: "Descripción",
          align: "start",
          value: "description",
          class:"file-name-table",
          width: 400
        },
        { text: "Link", value: "link",  class:"file-name-table", },
        { text: "Acciones", value: "actions",  class:"file-name-table", sortable: false },
      ],
      permissionsAll: permissions,
    };
  },
  methods: {
    async editItem(item) {
      await this.$store.dispatch("removeBrandSelectedAction");
      router.push(`${links.brand.update}/${item.id}`);
    },
    async deleteItem(item) {
      const msg = `¿Esta seguro que desea eliminar la marca "${item.description}"?`;
      await this.$store.dispatch("setIdItemDialogAction", item.id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      await this.$store.dispatch("setDialogShowAction");
    },
    async deleteBrand() {
      await beginRequest();
      await this.$store.dispatch("setDialogShowAction");
      const { status, snack } = await this.$store.dispatch(
        "deleteBrandAction",
        this.ui.idItemDialog
      );
      await endRequest(snack, status);
    },
  },
  computed: {
    ...mapState({
      brands: (state) => state.brands,
      ui: (state) => state.ui,
      user: (state) => state.user,
    }),
  },
  async created() {
    await beginRequest();
    const { status, snack } = await this.$store.dispatch("setBrandsAction");
    await endRequest(snack, status);
  },
};
</script>

<style></style>
