<template lang="">
  <div>
    <LoaderLinear />
    <v-card  elevation="0" class="pa-5 ma-2">
      <form @submit.prevent="sendForm" id="formBrand">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.description.$model"
              label="Naming de la Marca Principal (*)"
              :error-messages="descriptionErrors"
              @blur="$v.description.$touch()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.link.$model"
              :error-messages="linkErrors"
              @blur="$v.link.$touch()"
              label="Web de la marca (*)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <span class="fvl-title-form">
              Submarcas
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              class="mx-2 float-right fvl-btn green-primary"
              icon
              title="Agregar SubMarca"
              @click="addField"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="fields.length > 0">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-for="(field, index) in fields" :key="index">
          <v-text-field label="id" v-model="field.id" class="input-hidden" outlined>
          </v-text-field>
          <v-col cols="12" md="6">
            <v-text-field label="Naming o denominación de la submarca" v-model="field.description" outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              label="Web de la submarca"
              v-model="field.link"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="1" class="align-self-center">
            <v-btn
              depressed
              class="float-right mt-6"
              icon
              small
              @click="removeItem(index)"
            >
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Button
              :disabled="$v.$invalid"
              classes="mr-4 float-right fvl-btn green-primary"
              type="submit"
              name="Guardar"
            />
            <router-link :to="goToBack">
              <Button
                :disabled="false"
                classes="mr-4 float-right fvl-btn green-military"
                type="button"
                name="Volver"
              />
            </router-link>
          </v-col>
        </v-row>
        </v-row>
      </form>
      <Overlay />
    </v-card>
    <SnackBar />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { LoaderLinear, SnackBar, Overlay, Button } from "../_commons";
import { beginRequest, endRequest, links } from "../../helpers";

export default {
  name: "FormBrand",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
    Button,
  },
  props: ["actionForm"],
  mixins: [validationMixin],
  validations: {
    description: { required, minLength: minLength(3) },
    link: { required, minLength: minLength(6) },
  },
  data() {
    return {
      goToBack: links.brand.list,
      description: "",
      link: "",
      fields: [],
    };
  },
  methods: {
    sendForm() {
      const formData = {
        description: this.description,
        link: this.link,
        fields: this.fields,
      };
      this.$emit("sendForm", formData);
    },
    resetFormMethod() {
      this.fields = [];
      this.description = null;
      this.link = null;
      this.$v.$reset();
    },
    addField() {
      // DEBO AGREGARLOS AL STORE
      this.fields.push({
        id:"",
        description: "",
        link: "",
      });
    },
    removeItem(index) {
      // QUITARLO DEL STORE
      this.fields.splice(index, 1);
    },
  },
  computed: {
    ...mapState({
      brands: (state) => state.brands,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.description.required &&
        errors.push("El naming es un campo requerido.");
      return errors;
    },
    linkErrors() {
      const errors = [];
      if (!this.$v.link.$dirty) return errors;
      !this.$v.link.minLength &&
        errors.push("La longitud minima es de 6 caracteres");
      !this.$v.link.required && errors.push("El enlace es un campo requerido.");
      return errors;
    },
  },
  async created() {
    if (this.actionForm === "Edit") {
      await beginRequest();
      const { snack, status } = await this.$store.dispatch("setBrandSelectedAction", this.$route.params.idbrand);            
      if (this.brands.brandSelected) {
        this.description=this.brands.brandSelected.description;
        this.link=this.brands.brandSelected.link;
        this.fields=this.brands.brandSelected.get_sub_brands;        
      }
      await endRequest(snack, status);    
    }
  },
};
</script>
<style scoped>
.input-hidden{
  display: none;
}
</style>